import React, { useState } from "react";
import "./ResetPassword.css";
//
import { useParams } from "react-router-dom";

//api methods
import { ResetUserPassword } from "../../../API/userAPI";
import { error } from "console";

const RestPassword = () => {
  //tenant uuid from the url
  type Params = {
    token: string; // Or use '?' if it might be optional
  };
  let { token } = useParams<Params>();

  //states
  const [UserNewPassword, setUserNewPassword] = useState("");
  const [UserConfirmPassword, setUserConfirmPassword] = useState("");
  // const [TenantUUID, setTenantUUID] = useState<String>(tenantUUID);
  //method to handle reset password
  const HandleResetPassword = async (e: any) => {
    e.preventDefault();
    try {
      if (UserNewPassword != UserConfirmPassword) {
        throw new Error("Password Not Confiremd");
      }
      await ResetUserPassword(token as String, UserNewPassword);
    } catch (err) {
      console.log(err);
      alert(err);
    }
  };
  //
  return (
    <div className="ResetMain">
      <div className="ResetContainer">
        <h1>Rest Password</h1>
        <form onSubmit={HandleResetPassword}>
          <label>
            New Password
            <input
              type="password"
              onChange={(e) => setUserNewPassword(e.target.value)}
              required
            ></input>
          </label>
          <label>
            Confirm Password
            <input
              type="password"
              onChange={(e) => setUserConfirmPassword(e.target.value)}
              required
            ></input>
          </label>
          <button type="submit">Reset Password</button>
        </form>
      </div>
    </div>
  );
};

export default RestPassword;
