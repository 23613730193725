import React, { useState } from "react";
import "./ForgotPassword.css";
//
import { useParams } from "react-router-dom";

//api methods
import { ForgotUserPassword } from "../../../API/userAPI";

const ForgetPassword = () => {
  //tenant uuid from the url
  type Params = {
    tenantUUID: string; // Or use '?' if it might be optional
  };
  let { tenantUUID } = useParams<Params>();

  //states
  const [TenantEmail, setTenantEmail] = useState("");
  // const [TenantUUID, setTenantUUID] = useState<String>(tenantUUID);
  //method to handle reset password
  const HandleResetPassword = async (e: any) => {
    e.preventDefault();
    try {
      await ForgotUserPassword(tenantUUID as String, TenantEmail);
    } catch (err) {
      console.log(err);
    }
  };
  //
  return (
    <div className="ResetMain">
      <div className="ResetContainer">
        <h1>Forgot Password</h1>
        <form onSubmit={HandleResetPassword}>
          <label>
            Your Email
            <input
              type="email"
              onChange={(e) => setTenantEmail(e.target.value)}
              required
            ></input>
          </label>
          <button type="submit">Reset Password</button>
        </form>
      </div>
    </div>
  );
};

export default ForgetPassword;
